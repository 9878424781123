import React, { useLayoutEffect, useEffect, useState } from 'react';
import { Footer } from "../../Components/Footer"
import { Header } from "../../Components/Header"

import totusProd1 from "../../assets/new_prods/web/webSTCover1.png";
import totusMProd1 from "../../assets/new_prods/web/webSTMCover1.png";
import totusProd2 from "../../assets/new_prods/web/webSTItem1.png";
import totusProd3 from "../../assets/new_prods/web/webSTItem2.png";

import BailunaProd1 from "../../assets/new_prods/web/webNDCover1.png"
import BailunaMProd1 from "../../assets/new_prods/web/webNDMCover1.png"
import BailunaProd2 from "../../assets/new_prods/web/webNDItem1.png"
import BailunaProd3 from "../../assets/new_prods/web/webNDItem2.png"

import VayoProd1 from "../../assets/new_prods/web/webTHCover1.png"
import VayoMProd1 from "../../assets/new_prods/web/webTHMCover1.png"
import VayoProd2 from "../../assets/new_prods/web/webTHItem1.png"
import VayoProd3 from "../../assets/new_prods/web/webTHItem2.png"

import "./services.css"
import { ServiceSection } from "../../Components/services/ServiceSection";
import { useLocation } from 'react-router-dom';

const packageProsData = [
  {
    tagName: 'VAYO',
    bannerImg: totusProd1,
    mobileBanner: totusMProd1,
    leftImg: totusProd2,
    rightImg: totusProd3,
    brief: `Vayo needed a logo and website reflecting their success in establishing remote teams for award-winning tech staffing and maintenance businesses. With 85% of their staff working remotely, Vayo aimed to showcase their expertise in creating efficient, professional teams, gaining a competitive edge in the market.`,
    process: `We initiated the project by understanding Vayo's remote work success story. Crafting a logo that mirrored their professionalism and uniqueness, we then designed a user-friendly website. Showcasing the benefits of remote teams and the impact on workload, we highlighted their competitive advantage. The result: a compelling online presence capturing the essence of Vayo's success and their commitment to making the remote magic accessible for clients.`,
    process1: '',
    review: `Thrilled with the logo and website from the team! They totally got Vayo's vibe—showcasing our success in remote staffing with style. The design screams efficiency and professionalism, giving us a killer edge in the tech market. Remote staff never looked this good! Major props for capturing our story flawlessly!`,
    reviewUser: `Ran. V`,
    reserved: false,
  },
  {
    tagName: 'BAILUNA',
    bannerImg: BailunaProd1,
    mobileBanner: BailunaMProd1,
    leftImg: BailunaProd2,
    rightImg: BailunaProd3,
    brief: `Bailuna, a prominent hat boutique in Madrid, required a logo and website to showcase their exquisite range of designer hats. Specialising in fusing traditional craftsmanship with modern design, Bailuna Hats aimed to highlight their commitment to elegance, skill, and unmatched flair in the vibrant fashion scene of Spain's capital.`,
    process: `We delved into Bailuna's brand essence, creating a logo with mysticism and intrigue. The website seamlessly fused traditional craftsmanship with modern design, mirroring each hat's unique blend. The user-friendly site showcased a diverse hat collection, emphasising Bailuna's commitment to individuality and elegance, catering to diverse fashion tastes and occasions.`,
    process1: '',
    review: `Absolutely stoked with the logo and website the team whipped up for Bailuna Hats! They nailed the fusion of traditional craftsmanship with modern vibes, perfectly capturing our commitment to elegance and flair. Our hats now shine online, reflecting the vibrant fashion scene in Madrid. Super impressed with the outcome!`,
    reviewUser: `Eleanor. M`,
    reserved: true,
  },
  {
    tagName: 'Wolfus',
    bannerImg: VayoProd1,
    mobileBanner: VayoMProd1,
    leftImg: VayoProd2,
    rightImg: VayoProd3,
    brief: `Wolfus, a startup dog food brand, sought a comprehensive branding package encompassing logo design, website development, packaging, brand story creation, advertising, and social media presence. They envisioned a brand that embodied purposeful nutrition, crafted with care by experts to promote the well-being and happiness of dogs.`,
    process: `In addressing Wolfus' needs, we began by conducting a thorough brand analysis. We translated the brand essence into a visually striking logo and extended it, including a user-friendly website and engaging social media content. Our advertising efforts aimed to communicate Wolfus' commitment to long-term health and vitality, reinforcing the brand's dedication to exceptional care for furry family members.`,
    process1: '',
    review: `Over the moon with what the team did for Wolfus! They nailed it with the logo, website, packaging, and more. Our brand story came to life. They captured the essence of purposeful nutrition, crafting a brand that truly cares for our furry friends. Top-notch work!`,
    reviewUser: `Michelle. D & Brad. F`,
    reserved: false,
  },
]

export const Web = () => {
  let location = useLocation();

  useEffect(() => {
    if(location.hash){
      const viewElement = document.getElementById(`pageNum${location.hash}`)
      viewElement.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
    }else{
      window.scrollTo(0, 0)
    }
   }, [])

    return (
        <div className="service-pages bg-[#0e0e0e] py-2 md:pt-4 lg:pt-4 md:pb-32 lg:pb-32">
          <Header />
          {packageProsData.map((packageData, key) => (
            <ServiceSection 
            key={key}
            pageIndex={key}
            isScrollAnimation={key !== 0} reserved={packageData.reserved}
            mobileBanner={packageData.mobileBanner}
            bannerImg={packageData.bannerImg} prod1={packageData.leftImg} prod2={packageData.rightImg}
            tagName={packageData.tagName} brief={packageData.brief} process={packageData.process} 
            process1={packageData.process1} review={packageData.review} reviewuser={packageData.reviewUser}
            />
          ))}
          <div className='w-full py-20'></div>
          <Footer />
        </div>
      )
}